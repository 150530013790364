<template>
  <fragment>
    <v-card
      v-for="(favorite, index) in favorites"
      :key="index"
      @click="panTo(favorite)"
    >
      <v-img
        :src="favorite.dataURL"
        height="100px"
      ></v-img>
      <v-card-title>{{ favorite.name }}</v-card-title>
      <v-card-text>{{ favorite.address }}</v-card-text>
      <!-- <v-card-text>{{ favorite.desc }}</v-card-text> -->
    </v-card>
  </fragment>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {},
    watch: {},
    methods: {
      panTo(favorite) {
        this.currentCenter = _.cloneDeep(favorite.position)
        this.tabId = 'tab0'
      },
    },
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    margin: 16px;
  }
  .v-card__title {
    padding: 16px 16px 8px;
  }
  .v-card__text {
    padding: 0 16px 16px;
  }
</style>
