<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    origin="center bottom"
  >
    <v-card v-if="favorite">
      <v-toolbar>
        <v-btn
          icon
          @click="favorite = null"
        ><v-icon>mdi-chevron-down</v-icon></v-btn>
        <v-toolbar-title>{{ newFavorite.name }}</v-toolbar-title>
      </v-toolbar>
      <v-img><img :src="selectedPlace.dataURL"></v-img>
      <v-card-text>
        <v-text-field
          label="住所"
          v-model="newFavorite.address"
        ></v-text-field>
        <v-text-field
          label="ラベル"
          v-model="newFavorite.name"
        ></v-text-field>
        <v-text-field
          label="メモ"
          v-model="newFavorite.desc"
        ></v-text-field>
        <v-text-field
          label="座標"
          :value="_.round(favorite.position.lat, 5) + ', ' + _.round(favorite.position.lng, 5)"
          disabled
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="warning"
          @click="favorite = null"
        >削除</v-btn>
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          @click="setFavorites"
        >OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        newFavorite: null,
      }
    },
    computed: {
      dialog() { return this.favorite ? true : false },
    },
    async mounted() {},
    watch: {
      favorite() {
        if (this.favorite) {
          this.newFavorite = _.cloneDeep(this.favorite)
        }
      }
    },
    methods: {
      setFavorites() {
        const favorites = _.filter(this.favorites, favorite => { return favorite.id !== this.newFavorite.id})
        const favorite = this.getNewFavorite()
        this.favorites = _.concat([favorite], favorites)
        this.favorite = null
        this.selectedPlace = null
      },
      getNewFavorite() {
        if (! this.favorite.id) {
          return _.assign(this.newFavorite, {id: _.random(0, 1000)})
        } else {
          return this.newFavorite
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-dialog__content {
    z-index: 9999 !important;
  }
  .v-image {
    img {
      width: 100%;
      height: auto;
    }
  }
</style>
