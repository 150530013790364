// common mixin
import INIT from '../../init'
import { AdMob, BannerAdSize, BannerAdPosition } from '@capacitor-community/admob'

export default {
  data: {
  },
  computed: {
  },

  methods: {
    // admob
    admobShow() {
      if (this.platform !== 'web') {
        AdMob.initialize()
        const options = {
          adId: INIT.API_KEY.admob.android,
          adSize: BannerAdSize.BANNER,
          position: BannerAdPosition.BOTTOM_CENTER,
          margin: 0,
          isTesting: true,
          npa: true,
        };
        AdMob.showBanner(options)
      }
    },
    admobHide() {
      if (this.platform !== 'web') {
        AdMob.removeBanner()
      }
    },
  },
}
