<template>
  <fragment>
    <v-toolbar
      fixed
      :elevation="toolbarDialog ? 1 : 6"
      :class="{ ui: true, 'hide-top': ! showUI || tabId !== 'tab0' }"
    >
      <v-app-bar-nav-icon
        v-if="! toolbarDialog"
        @click.stop="drawer = true"
      ></v-app-bar-nav-icon>
      <v-btn
        icon
        v-else
        @click="toolbarDialog = false"
      ><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-text-field
        id="search"
        hide-details
        clearable
        solo
        :append-icon="keyword ? '' : 'mdi-magnify'"
        :placeholder="$t('placeholer')"
        v-model="keyword"
        @click="click"
        @compositionstart="compositionstart"
        @compositionend="compositionend"
      ></v-text-field>
    </v-toolbar>
    <ToolbarDialog />
  </fragment>
</template>

<script>
  import ToolbarDialog from './MapUIsToolbarDialog.vue'

  export default {
    data() {
      return {
        isComposing: false,
      }
    },
    computed: {
      trimedKeyword() { return this.keyword ? this.keyword.trim() : '' }
    },
    async mounted() {},
    watch: {
      async trimedKeyword() {
        // 削除時&半角英数字
        if (! this.isComposing) {
          this.query = this.trimedKeyword
        }
      }
    },
    methods: {
      click() {
        this.toolbarDialog = true
      },
      compositionstart() {
        this.isComposing = true
      },
      async compositionend() {
        // 入力確定時
        this.isComposing = false
        this.query = this.trimedKeyword
      },
    },
    i18n: {
      messages: {
        ja: {
          placeholer: 'ここで検索できます',
        },
        en: {
          placeholer: 'Search map...',
        },
      },
    },
    components: { ToolbarDialog },
  }
</script>

<style lang="scss" scoped>
  .v-toolbar {
    margin: 3%;
    width: 94%;
    z-index: 1000 !important;
  }
</style>
