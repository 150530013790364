import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '../commons/modules/_i18n'
import _mixin from '../commons/modules/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: 'All Maps',
      appTitle: 'All Maps | 各種マップを表示するアプリ',
      appDesc: '各種マップを表示するアプリです。',
    }),
    en: Object.assign(_i18n.en, {
      appName: 'All Maps',
      appTitle: 'All Maps | App that displays various maps',
      appDesc: 'App that displays various maps.',
    }),
  }
})
