<template>
  <div id="map1"></div>
</template>

<script>
  import L from '../modules/leaflet'

  export default {
    data() {
      return {
        mapId: 'map1',
        map: null,
        clickTimeout: null,
        selectedMarkerLayer: null,
        favoritesMarkerLayer: null,
      }
    },
    computed: {
      isPrimary() { return this.primaryMap === this.mapId }
    },
    mounted() {
      this.map = L.draw({id: this.mapId, zoomControl: false})
      this.map.addGSILayer(this.age)
      this.addEvent()
      this.addFavoritesMarkers()
      // 画像情報作成用
      this.map.isDev = this.isDev

    },
    watch: {
      // 地図移動
      currentCenter(obj) {
        if (! this.isPrimary && this.map) {
          const fixedPosition = {lat: obj.lat + this.fixedGap.lat, lng: obj.lng + this.fixedGap.lng }
          this.map.panTo(fixedPosition, {animate: false})
        }
      },
      // ズーム変更
      currentZoom(num) {
        if (! this.isPrimary && this.map) {
          this.map.setZoom(num)
        }
      },
      // サイズ変更
      isTargetSwiping() {
        if (! this.isTargetSwiping) {
          this.map.refresh()
        }
      },
      // 年代変更
      age() {
        this.map.addGSILayer(this.age)
      },
      // marker追加
      favorites() {
        this.addFavoritesMarkers()
      },
      // marker削除
      selectedPlace() {
        if (! this.selectedPlace) {
          this.map.removeMarkerLayer(this.selectedMarkerLayer)
        }
      },
      // clear fixed gap
      // fixedGap() {
      //   if (this.fixedGap.lat === 0 && this.fixedGap.lng === 0) {
      //     this.map.refresh()
      //   }
      // },
    },
    methods: {
      addFavoritesMarkers() {
        this.map.removeMarkerLayer(this.favoritesMarkerLayer)
        this.favoritesMarkerLayer = L.layerGroup().addTo(this.map)
        _.forEach(this.favorites, favorite => {
          this.map.addFavoriteMarker(favorite, this.favoritesMarkerLayer, () => {
            this.selectedPlace = _.cloneDeep(favorite) // idがあること前提
          })
        })
        return this.favoritesMarkerLayer
      },
      addEvent() {
        this.map.on('drag zoom', () => {
          this.primaryMap = this.mapId
          this.currentCenter = this.map.getCenter()
          this.currentZoom = _.round(this.map.getZoom())
        })
        this.map.on('dragend', () => {
          this.primaryMap = null
          if (this.isDev) {
            this.baseTiles = _.cloneDeep(this.map.baseTiles)
          }
        })
        // click
        this.map.onClick(() => {
          if (this.selectedPlace) {
            this.selectedPlace = null
            this.map.removeMarkerLayer(this.selectedMarkerLayer)
          } else {
            this.showUI = ! this.showUI
          }
        })
        // Long press
        this.map.onPress(latlng => {
          this.map.removeMarkerLayer(this.selectedMarkerLayer)
          this.selectedMarkerLayer = this.map.addSelectedMarker(latlng)
          this.selectedPlace = {
            address: '東京都港区芝公園４丁目２−８',
            position: latlng,
            dataURL: null,
            isFavorite: false,
          }
          this.showUI = true
        })
      },
    },
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  }
</script>

<style>
</style>

<style lang="scss" scoped>
  #map1 {
    height: 100%;
    width: 100%;
  }
</style>
