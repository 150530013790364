<template>
  <fragment>
    <v-btn
      fab fixed small
      top right
      :class="{ ui: true, 'hide-top': ! showUI || tabId !== 'tab0' }"
      @click="dialog = true"
    ><v-icon color="primary">mdi-layers</v-icon></v-btn>

    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title>Display Settings</v-card-title>
        <v-card-subtitle>{{ $t('splitTitle') }}</v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="split">
            <v-radio
              v-for="item in splitOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-subtitle>{{ $t('mapTypeTitle') }}</v-card-subtitle>
        <v-card-text>
          <v-radio-group v-model="style">
            <v-radio
              v-for="item in mapTypeOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="dialog = false"
          >{{ $t('dialog.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </fragment>
</template>

<script>
  export default {
    data() {
      return {
        dialog: false,
        // 分割方法
        splitOptions: [{
          id: 'vertical',
          label: this.$t('vertical'),
        }, {
          id: 'horizontal',
          label: this.$t('horizontal'),
        }],
        // マップタイプ一覧
        mapTypeOptions: [{
          id: 'roadmap', // 互換性のため'road'ではなく'street'にすべき？
          label: this.$t('roadmap'),
        }, {
          id: 'satellite',
          label: this.$t('satellite'),
        }, {
          id: 'hybrid',
          label: this.$t('hybrid'),
        }],
      }
    },
    i18n: {
      messages: {
        ja: {
          splitTitle: '画面分割',
          vertical: '左右分割',
          horizontal: '上下分割',
          mapTypeTitle: '現在マップ表示',
          roadmap: '地図',
          satellite: '航空写真',
          hybrid: '航空写真（ラベル付き）',
        },
        en: {
          splitTitle: 'Screen split',
          vertical: 'Vertically',
          horizontal: 'Horizontally',
          mapTypeTitle: 'Map style',
          roadmap: 'Road Map',
          satellite: 'Satellite',
          hybrid: 'Satellite (label)',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-btn--fab {
    top: 90px;
  }
</style>
