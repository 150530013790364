/* global google */
import INIT from '../../init.js'
import { Loader } from "@googlemaps/js-api-loader"

// mapGoogle.jsの設定と要同一
const loader = new Loader({
  apiKey: INIT.API_KEY.gcp.web,
  version: 'weekly',
  libraries: ['places'],
})
loader.load().then()

class Places {
  constructor() {
  }

  // AUTOCOMPLETE
  async getAutocomplete(keyword) {
    // database
    const res = await axios.get('https://sirius.aroundit.net/places/autocomplete?keyword=' + keyword)
    if (_.has(res, 'data.length')) {
      console.log('from db')
      return res.data;
    } else {
      // api
      console.log('from api')
      const autocompleteService = new google.maps.places.AutocompleteService()
      autocompleteService.getPlacePredictions({
        input: keyword,
        componentRestrictions: {country: 'jp'},
      }, res => {
        if (res) {
          const places = []
          _.forEach(res, place => {
            places.push({
              fullText: place.description,
              primaryText: place.structured_formatting.main_text, 
              secondaryText: place.structured_formatting.secondary_text,
              placeId: place.place_id,
            })
          })
          axios.post('https://sirius.aroundit.net/places/autocomplete', {
            keyword: keyword,
            results: JSON.stringify(places),
          })
          return places
        } else {
          return null
        }
      })
    }
  }
  
  // 地点情報取得
  async getPlace(place) {
    // database
    const res = await axios.get('https://sirius.aroundit.net/places/place?placeId=' + place.placeId)
    if (_.has(res, 'data.placeId')) {
      console.log('from db')
      return res.data
    } else {
      // api
      console.log('from api')
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({
        placeId: place.placeId,
      }, res => {
        if (res.length) {
          place = {
            placeId: place.placeId,
            name: place.primaryText,
            formattedAddress: res.formatted_address,
            latlng: [res[0].geometry.location.lat(), res[0].geometry.location.lng()],
          }
          axios.post('https://sirius.aroundit.net/places/place', place)
          return place
        } else {
          return null
        }
      })
    }
  }

}

export default new Places()
