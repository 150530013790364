<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    touchless
    width="280"
    :mobile-breakpoint="INIT.MENU.pcOpen ? undefined : 2000"
  >
    <div class="inner">
      <!-- ヘッダー -->
      <v-card
        class="header"
        dark
        :color="isThemeDark ? '' : 'primary'"
      >
        <!-- <v-card-text><img src="/www/assets/logo.png"></v-card-text> -->
        <template v-if="platform==='web'">
          <v-card-title>
            <img class="logo" :src="require('../../assets/logo.png')"><br>
            {{ $t('appName') }}
          </v-card-title>
          <v-card-subtitle>{{ $t('appDesc') }}</v-card-subtitle>
        </template>
        <template v-else>
          <v-card-title>
            <img class="logo" :src="require('../../assets/logo.png')">
          </v-card-title>
        </template>
      </v-card>

      <v-list nav>
        <v-list-item-group
          v-model="pageId"
        >
          <v-list-item
            v-for="item in menu"
            :key="item.id"
            :value="item.id"
            @click="push(item.id)"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title[lang] }}
                <v-icon v-if="item.id === 'review'">mdi-open-in-new</v-icon>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <v-list class="banners">
        <v-list-item-group v-if="platform === 'web'">
          <v-list-item v-if="INIT.URLS.play">
            <a :href="INIT.URLS.play" target="_blank"><img :src="require('../../assets/google-play.png')" :alt="$t( 'download.play' )"></a>
          </v-list-item>
          <v-list-item v-if="INIT.URLS.app">
            <a :href="INIT.URLS.app" target="_blank"><img :src="require('../../assets/app-store.svg')" :alt="$t( 'download.app' )"></a>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- 差し込みコンテンツ -->
      <Banner />

      <!-- コピーライト -->
      <v-footer class="caption" absolute>
        <div
          v-if="platform === 'web'"
          class="language d-flex justify-center"
        >
          <a v-if="! isJa" href="/ja/">日本語</a>
          <span v-else>日本語</span>
          <a v-if="isJa" href="/">English</a>
          <span v-else>English</span>
        </div>
        <div class="copyright">
          <span v-if="INIT.SOURCE.name.ja">{{ $t('source') }}<a @click="windowOpen(INIT.SOURCE.url[lang])">{{ INIT.SOURCE.name[lang] }}</a></span>
          <br v-if="INIT.SOURCE.name.ja">
          <span>&copy; {{ dayjs().year() }} Wana Kijiji</span>
        </div>
      </v-footer>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import Banner from '../../components/Banner.vue'

  export default{
    data() {
      return {
        commonMenu: [{
          id: 'home',
          icon: 'mdi-home',
          to: '/',
          title: {ja: 'ホーム', en: 'Home'},
        }, {
          id: 'purchase',
          icon: 'mdi-cart',
          to: '/purchase',
          title: {ja: '広告非表示のご案内', en: 'Purchase'},
        }, {
          id: 'setting',
          icon: 'mdi-cog',
          to: '/setting',
          title: {ja: '設定', en: 'Setting'},
        }, {
          id: 'request',
          icon: 'mdi-comment-multiple',
          to: '/request',
          title: {ja: '機能追加のご要望', en: 'Requests'},
        }, {
          id: 'contact',
          icon: 'mdi-email',
          to: '/contact',
          title: {ja: 'お問い合わせ', en: 'Contact Us'},
        }, {
          id: 'review',
          icon: 'mdi-thumb-up',
          to: '/review',
          title: {ja: '評価して応援する', en: 'Write a review'},
        }, {
          id: 'privacy',
          icon: 'mdi-handshake',
          to: '/privacy',
          title: {ja: 'プライバシーポリシー', en: 'Privacy Policy'},
        }],
      }
    },
    computed: {
      menu() {
        let menu = [...this.commonMenu, ...this.INIT.MENU.extras] // オプショナルメニュー追加
        menu = _.map(this.INIT.MENU.order, id => { return _.find(menu, ['id', id]); }) // 並べ替え
        _.remove(menu, item => {
          return (
            (item.id === 'purchase' && this.platform !== 'android') // purchase除外
            || (item.id === 'review' && this.platfomr !== 'web') // review除外
          )
        })
        return menu
      },
    },
    watch: {
      // history back時にもthis.pageIdを変更
      '$route' (to) {
        const id = to.name.split('-')[0] // nameが'home-ja'である可能性
        if (id !== this.pageId) {
          this.pageId = id
        }
      },
    },
    async mounted() {
      // firebase analytics
      this.firebaseSetScreenName('home')
      // mobile or desktop
      if (this.INIT.MENU.pcOpen && this.isLargeScreen) {
        this.drawer = true
      }
    },
    methods: {
      push(id) {
        if (id !== this.pageId) {
          // 別ページへ
          if (id === 'review') {
            // review
            const prevPageId = this.pageId
            const scheme = (this.userAgent === 'android') ? this.INIT.URLS.play : this.INIT.URLS.app
            window.open(scheme, '_system')
            setTimeout(() => {
              this.pageId = prevPageId
            }, 0)
          } else {
            // review以外
            const to = this.isJa ? '/ja' + _.find(this.menu, ['id', id]).to: _.find(this.menu, ['id', id]).to
            this.$router.push(to).catch((err)=>{ console.log(err) })
            window.scrollTo(0, 0) // 最上段へスクロール
          }
          // firebase analytics
          this.firebaseSetScreenName(id)
        } else {
          // 再クリック
          setTimeout(() => {
            this.pageId = id          
          }, 0)
        }
        if (! this.isLargeScreen || ! this.INIT.MENU.pcOpen) {
          this.drawer = false
        }
      },
    },
    i18n: {
      messages: {
        ja: {
          download: {
            play: 'Google Playで手に入れよう',
            app: 'App Storeからダウンロード',
          },
        },
        en: {
          download: {
            play: 'Get it on Google Play',
            app: 'Download on the App Store',
          },
        },
      },
    },
    components: { Banner },
  }
</script>

<style lang="scss" scoped>
  .v-navigation-drawer {
    .inner {
      min-height: 100%;
      position: relative;
    }
    .header {
      border-radius: 0;
      margin-bottom: 10px;
      padding-top: calc(25px + env(safe-area-inset-top));
      text-align: center;
      .v-card__title {
        display: block;
        padding-top: 0;
      }
      .logo {
        width: 40px;
        height: auto;
      }
    }
    // banner
    .banners {
      padding: 30px 20px 80px;
      .v-list-item {
        padding-bottom: 10px;
      }
      a {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
    .v-footer {
      .language {
        width: 100%;
        font-size: 1rem;
        a,
        span {
          margin: 20px 10px;
        }
      }
      .copyright {
        width: 100%;
        text-align: center;
      }
    }
  }
</style>