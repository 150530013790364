<template>
  <v-card v-if="selectedPlace && ! favorite">
    <v-img>
      <img v-if="selectedPlace.dataURL" :src="selectedPlace.dataURL">
      <v-progress-circular
        v-else
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-img>
    <v-card-text v-if="selectedPlace.name">{{ selectedPlace.name }}</v-card-text>
    <v-card-text v-if="selectedPlace.address">{{ selectedPlace.address }}</v-card-text>
    <!-- <v-card-text v-if="selectedPlace.desc">{{ selectedPlace.desc }}</v-card-text> -->
    <v-card-text class="buttons">
      <v-row
        justify="space-between"
        no-gutters
      >
        <v-btn
          v-if="selectedPlace.id"
          outlined rounded
          @click="showDetail"
        ><v-icon left>mdi-information-outline</v-icon>詳細</v-btn>
        <v-btn
          v-else
          outlined rounded
          @click="addFavorites"
        ><v-icon left>mdi-label-multiple-outline</v-icon>保存</v-btn>
        <v-btn
          outlined rounded
          @click="share"
        ><v-icon left>mdi-share-variant</v-icon>シェア</v-btn>
        <v-btn
          outlined rounded
          disabled
        ><v-icon left>mdi-comment-text-outline</v-icon>投稿</v-btn>
        <v-btn
          outlined rounded
          @click="openGmap"
        ><v-icon left>mdi-open-in-new</v-icon>マップ</v-btn>
        <v-btn
          outlined rounded
          @click="download"
        ><v-icon left>mdi-download</v-icon>ダウンロード</v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { Share } from '@capacitor/share'

  export default {
    data() {
      return {}
    },
    computed: {
      position() {
        return this.selectedPlace ? {
          lat: _.round(this.selectedPlace.position.lat, 5),
          lng: _.round(this.selectedPlace.position.lng, 5)
        } : {}
      },
      query() {
        return 'title=' + encodeURIComponent('東京都港区') + '&lat=' + this.position.lat + '&lng=' + this.position.lng + '&z=' + this.currentZoom + '&age=' + this.age
      },
      name() {
        return this.position.lat + '_' + this.position.lng + '_' + this.currentZoom + '_' + this.age + '.jpg'
      },
    },
    async mounted() {},
    watch: {},
    methods: {
      showDetail() {
        this.favorite = _.cloneDeep(this.selectedPlace)
      },
      addFavorites() {
        this.favorite = {
          id: null, // placeId
          name: 'マイプレイス',
          address: this.selectedPlace.address,
          position: this.selectedPlace.position,
          desc: '1958年に竣工された高さ333mの電波塔。東京のシンボルとして知られ、高さ150mと250mの2つの展望台と飲食施設を備える。',
          dataURL: this.selectedPlace.dataURL,
        }
      },
      async share() {
        this.isLoadings = true
        await cst.put('share/' + this.name, this.selectedPlace.dataURL)
        this.isLoadings = false
        await Share.share({
          title: this.$t('appName'),
          url: this.INIT.URLS.pwa + '/share?' + this.query,
          dialogTitle: this.$t('appName'),
        }).catch(err => { alert(err) })
      },
      download() {
        let downloadEle = document.createElement('a')
        downloadEle.href = this.selectedPlace.dataURL
        downloadEle.download = this.name
        downloadEle.click()
      },
      openGmap() {
        const url = 'https://www.google.com/maps/@?api=1&map_action=map&center= ' + this.selectedPlace.position.lat+ ',' + this.selectedPlace.position.lng + '&zoom=' + this.currentZoom + '&basemap=satellite' 
        window.open(url, '_system')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    width: 100%;
  }
  .v-image {
    margin: 16px 16px 0 16px;
    width: 200px;
    ::v-deep .v-responsive__content {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 110px;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
  .v-card__text {
    padding: 8px 16px 0;
  }
  .buttons {
    padding: 16px;
    .row {
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start !important;
    }
    .v-btn {
      margin-right: 8px;
    }
  }
</style>
