<template>
  <div id="map-canvas">
    <div id="map-l"></div>
    <div id="map-g"></div>
  </div>
</template>

<script>
  import L from '../modules/leaflet'
  import MapGoogle from '../commons/modules/mapGoogle'
  import html2canvas from 'html2canvas'

  export default {
    data() {
      return {
        mapL: null,
        mapG: new MapGoogle(),
      }
    },
    computed: {},
    mounted() {
      // leaflet
      this.mapL = L.draw({id: 'map-l', zoomControl: false, attributionControl: false})
      this.mapL.addGSILayer(this.age)
      // google
      this.mapG.draw({id: 'map-g', disableDefaultUI: true})
    },
    watch: {
      // 年代変更
      age() {
        this.mapL.addGSILayer(this.age)
      },
      selectedPlace() {
        if (this.selectedPlace && ! this.selectedPlace.dataURL) {
          this.mapL.setView(this.selectedPlace.position, this.currentZoom + 1, {animate: false})
          setTimeout(() => {
            html2canvas(document.getElementById('map-canvas'), {
              allowTaint: true,
              useCORS: true,
              scale: 1,
            }).then(async canvas => {
              this.selectedPlace = _.assign(_.clone(this.selectedPlace), {dataURL: canvas.toDataURL('image/jpeg', 0.8)})
            })
          }, 500)
        }
      },
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
  #map-canvas {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 450px;
    width: 800px;
    z-index: -1;
  }
  #map-l {
    width: 50%;
    height: 100%;
  }
  #map-g {
    width: 50%;
    height: 100%;
  }
</style>
