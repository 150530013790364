<template>
  <v-app-bar
    app
    dark
		:color="isThemeDark ? '' : 'primary'"
    :height="appBarHeight"
    extension-height="42"
  >
    <v-app-bar-nav-icon
      v-if="! isLargeScreen || ! INIT.MENU.pcOpen"
      @click.stop="toggleDrawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <div class="flex-grow-1"></div>
    <slot></slot>
    <template v-if="tabItems" v-slot:extension>
      <v-tabs
        v-model="tabId"
        background-color="transparent"
        icons-and-text
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(tabItem, index) in tabItems"
          :key="index"
          :href="'#tab' + index"
        >
          {{ tabItem.title[lang] }}
          <v-icon>{{ tabItem.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
  export default {
    props: ['title', 'tabItems'],
    data() {
      return {
        name: 'theAppbar'
      }
    },
    computed: {},
    mounted() {},
    methods: {
      toggleDrawer() {
        this.drawer = ! this.drawer
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-app-bar {
    ::v-deep .v-toolbar__content {
      padding-top: calc(env(safe-area-inset-top) * 0.75);
      padding-bottom: 0;
    }
  }
  /* タブの文字列を少し上に移動 */
  .v-tabs--icons-and-text>.v-tabs-bar .v-tab>:first-child {
    margin-bottom: 1px !important;
  }
  /* 文字を小さめに */
  .v-tabs--icons-and-text>.v-tabs-bar .v-tab {
    padding-bottom: 3px;
    font-size: 11px;
    line-height: 1;
  }
</style>
