import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'long-press-event'

export default {
  commonMethods(map) {
    return {
      // click not dbclick
      onClick(callback) {
        if (! map) { return }
        let timeout = null
        let start = 0
        map.on('click', e => {
          clearTimeout(timeout)
          if (e.originalEvent.pointerType === 'touch') {
            // touch
            callback()
          } else {
            // click
            timeout = setTimeout(() => {
              const end = new Date().getTime()
              if (end - start < 800) {
                callback()
              }
            }, 300)  
          }
        })
        map.on('dblclick', () => {
          clearTimeout(timeout)
        })
        map.on('mousedown', () => {
          start = new Date().getTime()
        })
      },

      // long press event
      onPress(callback) {
        if (! map) { return }
        const ele = document.getElementById(map.options.id)
        ele.dataset.longPressDelay = 800
        ele.addEventListener('long-press', e => {
          callback(map.containerPointToLatLng(L.point(e.detail.clientX, e.detail.clientY)))
        })
      },

      // 再描画
      refresh() {
        if (! map) { return }
        map.invalidateSize()
      },

      // マーカー追加
      addMarker(position, icon, markerLayer) {
        return L.marker(position, {
          icon: L.icon(icon)
        }).addTo(markerLayer)
      },

      // マーカー削除
      removeMarkerLayer(markerLayer) {
        if (! map) { return }
        if (markerLayer) {
          map.removeLayer(markerLayer)
        }
      }

    }
  }
}



//   addPopup(marker, favorite) {
//     marker.bindPopup('<h1>' + favorite.name + '</h1><address>' + favorite.address + '</address><p>' + favorite.desc + '</p><button id="edit-marker' + favorite.id + '">編集</button>', {
//       maxWidth: (this.isVertical) ? this.bodyWidth * .35 : this.bodyWidth * 0.7,
//     })
//     marker.on('click', () => {
//       marker.openPopup()
//       // const editLink = document.getElementById('edit-marker' + favorite.id)
//       // editLink.addEventListener('click', () => {
//       //   this.$store.commit('app/setFavoriteId', favorite.id)
//       // })
//     })
//   }
