// common mixin
import INIT from '../../init'
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import vuetify from './vuetify'

// firebase
if (Capacitor.getPlatform() === 'web' && location.protocol !== 'http:') {
  INIT.FIREBASE.apiKey = INIT.API_KEY.gcp[Capacitor.getPlatform()]
  FirebaseAnalytics.initializeFirebase(INIT.FIREBASE)
}

export default {
  data: {
    INIT: INIT,
  },
  computed: {
    // ライブラリ
    _() { return _ },
    dayjs() { return dayjs },
    // platform: web, ios, android
    platform() { return Capacitor.getPlatform() },
    // user agent
    userAgent() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('iphone') !== -1 || ua.indexOf('ipod') !== -1 || ua.indexOf('ipad') !== -1) {
        return 'ios'
      } else if (ua.indexOf('android') !== -1) {
        return 'android'
      } else {
        return 'others'
      }
    },
    // PWA
    isPWA() { return window.matchMedia('(display-mode: standalone)').matches },
    // WEB not PWA
    isWeb() { return this.platform === 'web' && ! this.isPWA },
    // Development Mode
    isDev() {
      return (location.protocol === 'http:' && this.platform === 'web') ? true : false
    },
    // 大きなスクリーン
    isLargeScreen() { return this.bodyWidth >= 1264 },
    // 言語（this.platformは使えない！）
    lang() {
      if (Capacitor.getPlatform() === 'web') {
        // PWA（英語ファースト）
        return (_.includes(location.pathname, '/ja/')) ? 'ja' : 'en'
      } else {
        // WevView
        return (navigator.language.match(/ja/)) ? 'ja' : 'en'
      }
    },
    // 日本語判定
    isJa() { return (this.lang === 'ja') },
    // ダークテーマ判定
    isThemeDark() {
      const isDark =  (
        this.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches
        || this.theme === 'dark'
      )
      vuetify.framework.theme.dark = isDark // vuetify theme
      return isDark
    },
    // footer adsense hight
    adsenseHeight() {
      return (this.bodyWidth > 800) ? 90 : (this.bodyWidth > 500) ? 60 : 100
    },
  },

  methods: {
    // firebase analytics
    firebaseSetScreenName(screenName) {
      if (this.platform !== 'web') {
        FirebaseAnalytics.setScreenName({ screenName: screenName })
      }
    },
    // browser
    windowOpen(url) {
      Browser.open({ url: url })
    },
    // sleep
    sleep(time) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
  },
}
