<template>
  <v-app id="app">
    <AppInitialize />
    <AppDrawer />
    <!-- vue-fragment@1.5.1固定 -->
    <router-view />
    <AppNetwork />
    <AppLoading />
    <Adsense />
  </v-app>
</template>

<script>
  import AppInitialize from './commons/components/AppInitialize.vue'
  import AppDrawer from './commons/components/AppDrawer.vue'
  import AppNetwork from './commons/components/AppNetwork.vue'
  import AppLoading from './commons/components/AppLoading.vue'
  import Adsense from './commons/components/Adsense.vue'

  export default {
    components: { AppInitialize, AppDrawer, AppNetwork, AppLoading, Adsense },
  }
</script>

<style lang="scss">
  #app {
    overscroll-behavior: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  // 大画面時のダイアログサイズ制限
  .v-dialog:not(.v-dialog--fullscreen) {
    max-width: 400px !important;
  }
  // 全画面ダイアログの角丸
  .v-dialog--fullscreen > .v-card {
    border-radius: 0 !important;
  }
</style>
