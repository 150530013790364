// map mixin
import { Geolocation } from '@capacitor/geolocation'

export default {
  methods: {
    // 現在置取得
    async getLocation() {
      const location = await Geolocation.getCurrentPosition()
      return _.get(location, 'coords') ? {lat: location.coords.latitude, lng: location.coords.longitude} : null;
    },
    // 現在値追跡
    watchLocation(options, callback) {
      options = _.assign({
        enableHighAccuracy: true
      }, options)
      Geolocation.watchPosition(options, (location, err) => {
        if (_.get(location, 'coords')) {
          callback({lat: location.coords.latitude, lng: location.coords.longitude})
        } else if (err) {
          console.log(err)
          callback(null)
        }
      })
    },
  },
}
