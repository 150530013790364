import INIT from '../../init.js'
import { Capacitor } from '@capacitor/core'
import { initializeApp } from "firebase/app"
import { getAuth, signInAnonymously } from "firebase/auth"
import { getStorage, ref, uploadString, updateMetadata, getDownloadURL } from 'firebase/storage'

INIT.FIREBASE.apiKey = INIT.API_KEY.gcp[Capacitor.getPlatform()]
initializeApp(INIT.FIREBASE)

class Storage {
  constructor() {
    this.storage = getStorage()
    this.isReady = (() => {
      // Authlization
      return new Promise((resolve, reject) => {
        const auth = getAuth()
        signInAnonymously(auth).then(() => {
          resolve(true)
        }).catch(() => {
          console.log('login error')
          reject(false)
        })
      })
    })()
  }

  async put(name, str) {
    const fileRef = ref(this.storage, name)
    const snapshot = await uploadString(fileRef, str, 'data_url').catch(() => { console.log('put error') })
    await updateMetadata(fileRef, {
      cacheControl: 'public,max-age=' + (24 * 60 * 60),
      // contentType: 'image/jpeg',
    }).catch(() => { console.log('meta error') })
    const url = await getDownloadURL(snapshot.ref)
    return url ? url.split('&token')[0] : null
  }

}

export default new Storage()
