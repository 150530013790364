<template>
  <fragment>
    <v-speed-dial
      v-model="fab"
      absolute
      bottom left
      v-show="! selectedPlace"
      :class="{ ui: true, 'hide-top': ! showUI || tabId !== 'tab0' }"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          fab
          color="primary"
        >
          <v-icon v-if="fab">mdi-close</v-icon>
          <span v-else>{{ age }}</span>
        </v-btn>
      </template>
      <v-btn
        fab small
        v-for="layer in GSI_LAYERS"
        :key="layer.age"
        :disabled="! layer.isExist"
        @click="age = layer.age"
      ><span>{{ layer.age }}</span></v-btn>
    </v-speed-dial>

    <v-snackbar v-model="snackbar">
      {{ $t('noImage') }}
      <v-btn
        text
        @click="snackbar = false"
      >{{ $t('dialog.close') }}</v-btn>
    </v-snackbar>
  </fragment>
</template>

<script>
  import GSI_LAYERS from '../jsons/layers.json'

  export default {
    data() {
      return {
        GSI_LAYERS: GSI_LAYERS,
        fab: false,
        snackbar: false,
        timeout: null,
      }
    },
    watch: {
      currentCenter() {
        if (this.fab) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.checkExist()
          }, 1000)
        }
      },
      fab() {
        if (this.fab) {
          this.checkExist()
        }
      }
    },
    methods: {
      // 年代タイル存在確認
      checkExist() {
        const zoom = (this.currentZoom > 17) ? 17 : this.currentZoom
        const centerTile = {
          wide: this.getTiles(this.currentCenter, 10),
          current: this.getTiles(this.currentCenter, zoom),
        }
        _.forEach(GSI_LAYERS, async layer => {
          if (layer.age !== 76) {
            const tileStrs = _.map(layer.tiles, tile => { return JSON.stringify(tile) })
            const isWideExist = _.includes(tileStrs, JSON.stringify(centerTile.wide))
            if (isWideExist) {
              layer.isExist = this.isExist(layer, centerTile.current, zoom)
            } else {
              layer.isExist = false
            }
          } else {
            layer.isExist = true
          }
        })
      },
      // 画像の存在確認
      isExist(layer, targetTile, zoom) {
        const url = 'https://cyberjapandata.gsi.go.jp/xyz/' + layer.dir + '/' + zoom + '/' + targetTile.x + '/' + targetTile.y + '.' + layer.ext
        const xhr = new XMLHttpRequest()
        xhr.open('HEAD', url, false)
        xhr.send(null)
        return xhr.status === 200 ? true : false
      },
      // 特定のズームに対するタイル番号
      getTiles(coords, zoom) {
        return {
          x: Math.floor((coords.lng + 180) / 360 * Math.pow(2, zoom)),
          y: Math.floor((1 - Math.log(Math.tan(coords.lat * Math.PI / 180) + 1 / Math.cos(coords.lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)),
        };
      },
    },
    i18n: {
      messages: {
        ja: {
          noImage: 'この地域ではその年代に対応していません',
        },
        en: {
          noImage: 'There is no old satellite images.',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-speed-dial {
    bottom: 70px !important;
  }
</style>
