<template>
  <fragment>
    <v-list v-show="! isFixingGap">
      <!-- Gap -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t('mapType') }}</v-list-item-title>
          <v-radio-group v-model="style">
            <v-radio
              v-for="item in mapTypeOptions"
              :key="item.id"
              :value="item.id"
              :label="item.label"
            ></v-radio>
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
      <v-divider inset />
      <!-- Gap -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t('fixGap') }}</v-list-item-title>
          <v-list-item-subtitle>
            緯度：{{ _.round(fixedGap.lat, 6) }}, 経度：{{ _.round(fixedGap.lng, 6) }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="isFixingGap = true"
          >調整する</v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider inset />
      <!-- Theme -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $t('theme') }}</v-list-item-title>
          <v-radio-group v-model="theme">
            <v-radio
              v-for="themeOption in themeOptions"
              :key="themeOption.id"
              :label="themeOption.label[lang]"
              :value="themeOption.id"
            ></v-radio>
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <Tab3Fixing />

  </fragment>
</template>

<script>
  import Tab3Fixing from './Tab3Fixing.vue'

  export default {
    data() {
      return {
        // テーマ
        themeOptions: [
          {id: 'light', label: {ja: 'ライト', en: 'Light'}},
          {id: 'dark', label: {ja: 'ダーク', en: 'Dark'}},
          {id: 'system', label: {ja: 'システムデフォルト', en: 'System Default'}}
        ],
        // マップタイプ一覧
        mapTypeOptions: [{
          id: 'roadmap', // 互換性のため'road'ではなく'street'にすべき？
          label: this.$t('roadmap'),
        }, {
          id: 'satellite',
          label: this.$t('satellite'),
        }, {
          id: 'hybrid',
          label: this.$t('hybrid'),
        }],
      }
    },
    computed: {},
    async mounted() {},
    watch: {
    },
    methods: {
    },
    i18n: {
      messages: {
        ja: {
          title: '設定',
          mapType: 'マップタイプ',
          fixGap: '地図のずれ補整',
          theme: 'テーマ',
        },
        en: {
          title: 'Setting',
          mapType: 'Map Type',
          fixGap: 'Map Misalignment Correction',
          theme: 'Theme',
        }
      }
    },
    components: { Tab3Fixing },
  }
</script>

<style lang="scss" scoped>
  .v-input {
    .v-radio {
      margin: 0 0 14px 20px !important;
      &:last-child {
        margin: 0 0 0 20px !important;
      }
    }
    ::v-deep .v-messages {
      display: none;
    }
  }
</style>
