<template>
  <v-card
    v-show="isFixingGap"
  >
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            label="緯度"
            :value="_.round(fixedGap.lat, 6)"
            readonly
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            label="経度"
            :value="_.round(fixedGap.lng, 6)"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn
        outlined
        color="warning"
        @click="clear"
      >クリア</v-btn>
      <v-spacer />
      <v-btn
        @click="cancel"
      >キャンセル</v-btn>
      <v-btn
        @click="close"
      >保存</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        oldFixedGap: {lat: 0, lng: 0},
      }
    },
    computed: {},
    async mounted() {},
    watch: {
      isFixingGap() {
        if (this.isFixingGap) {
          this.oldFixedGap = _.cloneDeep(this.fixedGap)
        }
      }
    },
    methods: {
      // clear
      clear() {
        this.fixedGap = {lat: 0, lng: 0}
        setTimeout(() => {
          this.primaryMap = 'map2'
          this.currentCenter = _.cloneDeep(this.currentCenter)
          this.primaryMap = null
        }, 1000)
      },
      // 更新
      renew() {
        console.log('renew')
        this.fixedGap = _.cloneDeep(this.fixedGap)
      },
      // キャンセル
      cancel() {
        this.fixedGap = _.cloneDeep(this.oldFixedGap)
        this.isFixingGap = false
      },
      // 終了
      close() {
        this.isFixingGap = false
        this.tabId = 'tab0'
      }
    },
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card__text {
    padding-bottom: 0;
  }
  .v-input {
    ::v-deep .v-text-field__details {
      display: none;
    }
  }
</style>
