<template>
  <div
    class="adsense"
    :style="{height: adsenseHeight + 'px'}"
  >
    <Adsense
        data-ad-client="ca-pub-1693924705262886"
        data-ad-slot="1647164467">
    </Adsense>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    methods: {},
    i18n: {
      messages: {}
    },
    components: {},
  }
</script>

<style lang="scss" scoped>
  .adsense {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px; // JS
    background-color: black;
  }
</style>
