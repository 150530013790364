<template>
  <fragment>
    
    <TheAppbar :title="$t('title')"></TheAppbar>
    
    <v-main>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('theme') }}</v-list-item-title>
            <v-radio-group v-model="theme">
              <v-radio
                v-for="themeOption in themeOptions"
                :key="themeOption.id"
                :label="themeOption.label[lang]"
                :value="themeOption.id"
              ></v-radio>
            </v-radio-group>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-main>

  </fragment>
</template>

<script>
  import TheAppbar from '../commons/components/TheAppbar.vue'
  
  export default {
    data() {
      return {
        themeOptions: [
          {id: 'light', label: {ja: 'ライト', en: 'Light'}},
          {id: 'dark', label: {ja: 'ダーク', en: 'Dark'}},
          {id: 'system', label: {ja: 'システムデフォルト', en: 'System Default'}}
        ],
      }
    },
    computed: {},
    methods: {},
    i18n: {
      messages: {
        ja: {
          title: '設定',
          theme: 'テーマ',
        },
        en: {
          title: 'Setting',
          theme: 'theme',
        }
      }
    },
    components: { TheAppbar },
  }
</script>

<style lang="scss" scoped>
  .v-radio {
    margin: 0 0 14px 20px !important;
  }
</style>
