import L from 'leaflet'
import _leaflet from '../commons/modules/_leaflet'
import GSI_LAYERS from '../jsons/layers.json'

L.draw = options => {
  options = Object.assign({
    id: 'map', zoom: 15, center: {lat: 35.7052579, lng: 139.752536},
    attributionControl: false, zoomControl: true,
  }, options)
  const map = L.map(document.getElementById(options.id), options)
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18 }).addTo(map)
  return Object.assign(map, _leaflet.commonMethods(map), methods(map))
}

export default L

function methods(map) {
  return {

    // selected marker追加
    addSelectedMarker(latlng) {
      if (! map) { return }
      const iconUrl = require('../assets/map-marker-circle.svg')
      const selectedMarkerLayer = L.layerGroup().addTo(map)
      map.addMarker(latlng, {
        iconUrl: iconUrl,
        iconSize: [26, 26],
        iconAnchor: [13, 13],
        popupAnchor: [0, -13],
      }, selectedMarkerLayer)
      return selectedMarkerLayer
    },

    // favorites markers 追加
    addFavoriteMarker(favorite, markerLayer, clickFunc) {
      const iconUrl = require('../assets/map-marker.svg')
      const marker = this.addMarker(favorite.position, {
        iconUrl: iconUrl,
        iconSize: [30, 60],
        iconAnchor: [15, 45],
        popupAnchor: [0, -30],
      }, markerLayer)
      marker.on('click', clickFunc)
    },

    // 年代変更
    addGSILayer(age) {
      if (! map) { return }
      const layerInfo = _.find(GSI_LAYERS, layer => { return layer.age === age })
      const tiles = new L.GridLayer({
        maxNativeZoom: 17,
        minNativeZoom: 10
      })
      // 現在のレイヤー群を削除
      map.eachLayer(layer => { return map.removeLayer(layer) })
      // タイル画像読み込み
      tiles.createTile = coords => {
        const isRecording = (this.isDev && age === 76 && coords.z === 10) ? true : false
        const img = L.DomUtil.create('img')
        img.src = 'https://cyberjapandata.gsi.go.jp/xyz/' + layerInfo.dir + '/' + coords.z + '/' + coords.x + '/' + coords.y + '.' + layerInfo.ext
        // 読み込みエラー時
        img.onerror = () => {
          img.onerror = null
          if (! isRecording) {
            img.src = require('../assets/nodata.png')
          }
        }
        // 読み込み完了（レイヤー情報作成用）
        img.onload = () => {
          if (isRecording) {
            if (! _.find(this.baseTiles, tile => { return tile.x === coords.x && tile.y === coords.y })) {
              this.baseTiles.push({x: coords.x, y: coords.y})
            }  
          }
        }
        return img
      }
      tiles.addTo(map)
    },

  }
}