// common router
// lazy-loadを使うとvuetifyでエラー
import Home from '../../components/Home.vue'
import Contact from '../../commons/components/Contact.vue'
import Setting from '../../components/Setting.vue'
import Request from '../../commons/components/Request.vue'
import Privacy from '../../commons/components/Privacy.vue'

export default [{
  path: '/',
  name: 'home',
  component: Home
}, {
  path: '/setting',
  name: 'setting',
  component: Setting
}, {
  path: '/contact',
  name: 'contact',
  component: Contact
}, {
  path: '/request',
  name: 'request',
  component: Request
}, {
  path: '/privacy',
  name: 'privacy',
  component: Privacy
}, {
  path: '/ja/',
  name: 'home-ja',
  component: Home
}, {
  path: '/ja/setting',
  name: 'setting-ja',
  component: Setting
}, {
  path: '/ja/contact',
  name: 'contact-ja',
  component: Contact
}, {
  path: '/ja/request',
  name: 'request-ja',
  component: Request
}, {
  path: '/ja/privacy',
  name: 'privacy-ja',
  component: Privacy
}, { // 外部Data読み込み用（必ず最後に読み込む）
  path: '/*',
  name: 'home',
  component: Home
}, { // 外部Data読み込み用（必ず最後に読み込む）
  path: '/ja/*',
  name: 'home-ja',
  component: Home
}]