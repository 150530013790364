<template>
  <fragment>
    <!-- <v-btn
      fab fixed
      top left
      small
      class="search"
      @click="search"
    ><v-icon color="primary">{{ isSearching ? 'mdi-stop' : 'mdi-play' }}</v-icon></v-btn>
    <v-btn
      fab fixed
      top left
      small
      class="base-tiles"
      @click="saveBaseTiles"
    ><v-icon color="primary">mdi-download</v-icon></v-btn> -->
  </fragment>
</template>

<script>
  import GSI_LAYERS from '../jsons/layers.json'
  import BASE_TILES from '../jsons/base-tiles.json'

  export default {
    data() {
      return {
        isSearching: false,
      }
    },
    watch: {},
    async mounted() {
    },
    methods: {
      async search() {
        this.isSearching = true
        for (const layer of GSI_LAYERS) {
          if (layer.age === 76) { continue }
          const checked = []
          const exist = []
          for (const tile of BASE_TILES) {
            const targetTiles = this.getTargetTiles(tile)
            for (const targetTile of targetTiles) {
              const tileStr = JSON.stringify(targetTile)
              if (! _.includes(checked, tileStr)) {
                if (this.isExist(layer, targetTile, 10)) {
                  console.log('exist')
                  exist.push(tile)
                }
                checked.push(tileStr)
                await this.sleep(100)
              }
            }
            // if (checked.length > 100) { break }
          }
          console.log(checked, exist)
          layer.tiles = exist
          await this.sleep(10000)
        }
        this.download('layers', GSI_LAYERS)
        this.isSearching = false
      },
      isExist(layer, targetTile, zoom) {
        const url = 'https://cyberjapandata.gsi.go.jp/xyz/' + layer.dir + '/' + zoom + '/' + targetTile.x + '/' + targetTile.y + '.' + layer.ext
        const xhr = new XMLHttpRequest()
        xhr.open('HEAD', url, false)
        xhr.send(null)
        return xhr.status === 200 ? true : false
      },
      getTargetTiles(tile) {
        return [
          {x: tile.x, y: tile.y}, {x: tile.x, y: tile.y+1}, {x: tile.x, y: tile.y-1},
          {x: tile.x+1, y: tile.y}, {x: tile.x+1, y: tile.y+1}, {x: tile.x+1, y: tile.y-1},
          {x: tile.x-1, y: tile.y}, {x: tile.x-1, y: tile.y+1}, {x: tile.x-1, y: tile.y-1}
        ]
      },
      saveBaseTiles() {
        console.log(this.baseTiles.length)
        this.download('base-tiles', this.baseTiles)
      },
      download(name, data) {
        const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
        const a = document.createElement('a')
        a.download = name + '.json'
        a.href = URL.createObjectURL(blob)
        a.click()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .search {
    top: 150px;
  }
  .base-tiles {
    top: 150px;
    left: 70px;
  }
</style>
