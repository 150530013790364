<template>
  <fragment>

    <v-btn
      fab absolute
      bottom right
      :class="{ ui: true, 'hide-top': ! showUI || tabId !== 'tab0' }"
      v-show="! selectedPlace"
      @click="getPosition"
    ><v-icon :color="(isLocated) ? 'primary' : ''">mdi-crosshairs-gps</v-icon></v-btn>

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
    >
      {{ $t('map.locationFailed') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >{{ $t('dialog.close') }}</v-btn>
      </template>
    </v-snackbar>

  </fragment>
</template>

<script>
  export default{
    data() {
      return {
        snackbar: false,
      };
    },
    computed: {},
    mounted() {},
    watch: {},
    methods: {
      // 現在地の取得
      async getPosition() {
        this.isLoading = true
        this.isLocated = false;
        this.watchLocation({}, (coords) => {
          coords = null
          if (coords) {
            this.isLocated = true
            this.primaryMap = null
            this.currentCenter = coords
            this.center = coords
          } else {
            this.snackbar = true;
          }
        })
        this.isLoading = false
      },
    },
    i18n: {},
  };
</script>

<style scoped>
  .v-btn {
    bottom: 70px !important;
  }
</style>
