import INIT from '../init.js'
import Vue from 'vue'
import Vuex from 'vuex'
import _store from '../commons/modules/_store'

Vue.use(Vuex)

// state
const state = Object.assign(_store.state, INIT.STORE_STATE)
// mutations
const mutations = (() => {
  const mutations = {}
  _.forEach(state, (init, key) => {
    mutations['set' + _.capitalize(key)] = (state, value) => {
      state[key] = value
    }
  })
  return mutations  
})()

export default new Vuex.Store({
  strict: true,
  namespaced: true,
  state: state,
  mutations: Object.assign(mutations, _store.mutations),
})
