<template>
  <fragment>
    <div
      class="map-uis"
      :style="{bottom: adsenseHeight + 'px'}"
    >
      <Toolbar />
      <FabTarget />
      <FabMapType />
      <FabLocation />
      <DialAges />
      <FabRecord v-if="isDev" />
    </div>
  </fragment>
</template>

<script>
  import Toolbar from './MapUISToolbar.vue'
  import FabTarget from './MapUIsFabTarget.vue'
  import FabMapType from './MapUIsFabMapType.vue'
  import FabLocation from './MapUIsFabLocation.vue'
  import DialAges from './MapUIsDialAges.vue'
  import FabRecord from './FabRecord.vue'

  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {},
    watch: {},
    methods: {},
    components: { Toolbar, FabTarget, FabMapType, FabLocation, DialAges, FabRecord },
  }
</script>

<style lang="scss">
  .ui {
    transition: transform .5s, opacity .5s;
  }
  .hide-top {
    transform: translateY(-20px);
    opacity: 0;
    pointer-events: none;
  }
  .hide-center {
    opacity: 0;
    pointer-events: none;
  }
  .hide-bottom {
    transform: translateY(20px);
    opacity: 0;
    pointer-events: none;
  }
</style>

<style lang="scss" scoped>
  .map-uis {
    position: fixed;
    top: 0;
    bottom: 100px; // JS
    left: 0;
    right: 0;
  }
</style>
