
const VER = 1.0;

export default Object.freeze({
	APP_ID: 'allmaps',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: '',
		isAdmin: false,
		data: {
      theme: 'system', // 'system', 'dark', 'light'
      center: {lat: 35.7052579, lng: 139.752536},
      zoom: 15,
			split: 'vertical', // vertical, horizontal (ver2.0で追加)
			style: 'hybrid', // ver2.0で追加
      histories: [], // ver3.0で追加
      favorites: [], // ver3.0で追加 id, position, address, name, desc
      fixedGap: {lat: 0, lng: 0}, // ver4.0で追加
    },
	},
  STORE_STATE: {
    showUI: true,
    age: 76,
    primaryMap : '', // タッチ開始マップ
    currentCenter: {},
    currentZoom: 0,
    isLocated: false,
    isTargetSwiping: false, // 中央ターゲット
    targetPosition: {x: 50, y: 50}, // 中央ターゲット
    toolbarDialog: false, // 検索結果表示
    keyword: '', // input keyword
    query: '', // places query
    baseTiles: [], // 画像確認データ
    selectedPlace: null, // 表示用 position, address, dataURL, isFavorite
    favorite: null, // 編集画面用
    isFixingGap: false,
  },
	URLS: {
    pwa: 'https://allmaps-2021.web.app',
		play: '',
		app: '',
    privacy: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/557',
    request: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/574',
	},
	API_KEY: {
		admob: {
			android: 'ca-app-pub-3940256099942544/6300978111', // デモID
			ios: 'ca-app-pub-3940256099942544/2934735716', // デモID
		},
    gcp: {
      web: 'AIzaSyCWOoo20vW6x6rRDs2NN8LYm1RrAGbrBpk',
      android: '',
      ios: '',
    },
	},
  FIREBASE: {
    authDomain: "allmaps-2021.firebaseapp.com",
    projectId: "allmaps-2021",
    storageBucket: "allmaps-2021.appspot.com",
    messagingSenderId: "503260175055",
    appId: "1:503260175055:web:bf9c991f2a9b0b8fa775ba",
    measurementId: "G-FCW4X0J6TB"
  },
  SOURCE: {
    name: {
      ja: '参照元',
      en: 'refer',
    },
    url: {
      ja: 'https://www.google.co.jp/',
      en: 'https://www.google.co.jp/',
    },
  },
  // 表示メニュー一覧
  MENU: {
    order: ['home', 'setting', 'request', 'contact', 'review', 'privacy'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [], // item objectを入れて
  },
  COLOR: {
    light: {
      primary: '#2196f3',
      primaryBG: '#2196f3',
      secondary: '#FF9800',
      secondaryBG: '#FF9800',
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#1976D2', // +2
    },
    dark: {
      primary: '#42A5F5', // -1
      primaryBG: '#0D47A1', // +4
      secondary: '#FFA726', // -1
      secondaryBG: '#E65100', // +4
      accent: '#ff9800',
      error: '#ff5722',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#1E1E1E',
    }
  },
})
