<template>
  <div></div>
</template>

<script>
  import { StatusBar, Style } from '@capacitor/status-bar'
  import { App } from '@capacitor/app'

  export default{
    created() {
      // resize
      window.addEventListener('resize', _.debounce(() => {
        this.bodyWidth = window.innerWidth
        this.bodyHeight = window.innerHeight
        this.setSafeArea()
      }, 200))
      // scroll
      window.addEventListener('scroll', _.debounce(() => {
        this.scrollTop = window.pageYOffset
      }, 200))
      // storage
      let storage
      if (localStorage.getItem(this.INIT.APP_ID)) {
        try {
          storage = JSON.parse(localStorage.getItem(this.INIT.APP_ID))
        } catch(err){
          storage = this.INIT.LOCAL_DATA_STRUCTURE
        }
      } else {
        storage = this.INIT.LOCAL_DATA_STRUCTURE
      }
      this.$store.commit('setStorage', storage)
      // title
      document.title = this.$t('appTitle')
      // url parameter
      this.urlParams = this.getParams(location.href) // for browser
      App.addListener('appUrlOpen', data => {
        if (data && data.url) {
          this.urlParams = this.getParams(data.url) // for app
        }
      })
    },
    mounted() {
      // safe area for JS
      let looped = 0
      const interval = setInterval(() => {
        if (looped > 10) {
          clearInterval(interval)
        } else {
          this.setSafeArea()
        }
        looped++
      }, 200)
    },
    watch: {
      // ダークモード時のstatubbar
      isThemeDark: {
        immediate: true,
        handler() {
          if (this.platform !== 'web') {
            StatusBar.setStyle({ style: this.isThemeDark ? Style.Dark : Style.Light })
            StatusBar.setBackgroundColor({
              color: this.isThemeDark ? this.INIT.COLOR.dark.statusBar : this.INIT.COLOR.light.statusBar
            })
          }
        }
      },
    },
    methods: {
      // ios safearea
      setSafeArea() {
        const computedStyle = getComputedStyle(document.documentElement)
        this.safeArea = {
          top: _.toNumber(computedStyle.getPropertyValue("--sat").replace('px', '')),
          bottom: _.toNumber(computedStyle.getPropertyValue("--sab").replace('px', '')),
          left: _.toNumber(computedStyle.getPropertyValue("--sal").replace('px', '')),
          right: _.toNumber(computedStyle.getPropertyValue("--sar").replace('px', '')),
        }
        this.appBarHeight = 58 + this.safeArea.top
      },
      // query parameter
      getParams(param) {
        const urlParams = {}
        if (_.includes(param, '?')) {
          const params = param.split('?')[1].split('&')
          _.forEach(params, param => {
            urlParams[param.split('=')[0]] = param.split('=')[1]
          })
        }
        return urlParams
      },
    }
  }
</script>

<style>
  /* safe area for JS */
  :root {
    --sat: env(safe-area-inset-top);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
    --sar: env(safe-area-inset-right);
  }
</style>
