// common store
import INIT from '../../init'

export default {
  state: {
    storage: {},
    pageId: 'home',
    drawer: false,
    tabId: 'tab0',
    isLoadings: false, // add 's' for duplicate
    bodyWidth: window.innerWidth,
    bodyHeight: window.innerHeight,
    safeArea: {top: 0, bottom: 0, right: 0, left: 0,},
    appBarHeight: 56,
    scrollTop: 0,
    pullto: 'ready', // null, ready, pulling, loading, done
    urlParams: {},
  },
  mutations: {
    // web storage
    setStorage(state, data) {
      state.storage = data
      localStorage.setItem(INIT.APP_ID, JSON.stringify(data))
    },
  },
}