<template>
  <fragment>
      <div
        id="maps"
        :style="styleMaps"
      >
        <div
          id="map-box1"
          :style="styleBox1"
        >
          <Map1 />
          <div class="crosshair"></div>
        </div>
        <div
          id="map-box2"
          :style="styleBox2"
        >
          <Map2 />
          <div class="crosshair"></div>
        </div>
      </div>
      <MapCanvas />
  </fragment>
</template>

<script>
  import Map1 from './Map1.vue'
  import Map2 from './Map2.vue'
  import MapCanvas from './MapCanvas.vue'

  export default {
    data() {
      return {
        timeout: null
      }
    },
    computed: {
      styleMaps() {
        const direction = this.isVertical ? 'row' : 'column'
        return {
          flexDirection: direction,
          bottom: this.adsenseHeight + 'px'
        }
      },
      styleBox1() {
        if (this.isVertical) {
          return {width: this.targetPosition.x + '%', height: 100 + '%'}
        } else {
          return {width: 100 + '%', height: this.targetPosition.y + '%'}
        }
      },
      styleBox2() {
        if (this.isVertical) {
          return {width: 100 - this.targetPosition.x + '%', height: 100 + '%'}
        } else {
          return {width: 100 + '%', height: 100 - this.targetPosition.y + '%'}
        }
      },
    },
    async mounted() {
      // 初期位置
      this.currentCenter = _.clone(this.center)
      this.currentZoom = this.zoom
      this.split = 'horizontal'
    },
    watch: {
      currentCenter() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.center = _.clone(this.currentCenter)
        }, 1000)
      },
      currentZoom() {
        clearTimeout(this.timeout)
        this.zoom = this.currentZoom
      },
    },
    methods: {},
    components: { Map1, Map2, MapCanvas },
  }
</script>

<style lang="scss" scoped>
  #maps {
    position: fixed;
    top: 0;
    bottom: 100px; // JS
    left: 0;
    right: 0;
    z-index: 0;
    display: flex;
  }
  #map-box1 {
    height: 100%;
    width: 50%;
    background-color: red;
    position: relative;
  }
  #map-box2 {
    height: 100%;
    width: 50%;
    background-color: blue;
    position: relative;
  }
  .crosshair {
    pointer-events: none;
  }
  .crosshair:before,
  .crosshair:after {
    content: '';
    width: 30px;
    display: inline-block;
    border-top: .7px solid #fff;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    box-shadow: 0 .7px 0 #000;
  }
  .crosshair:after {
    transform: translate(-50%, -50%) rotate(90deg);
    box-shadow: 0 -0.7px 0 #000;
  }
</style>
