/* global */

import INIT from '../../init.js'
import { Loader } from "@googlemaps/js-api-loader"

// places.jsの設定と要同一
const loader = new Loader({
  apiKey: INIT.API_KEY.gcp.web,
  version: 'weekly',
  libraries: ['places']
})

export default class mapGoogle {
  constructor() {
    this.map = null
    this.isReady = false
    this.google = null
  }

  // 地図描画
  async draw(options) {
    options = Object.assign({
      id: 'map',
      zoom: 15,
      center: {lat: 35.7052579, lng: 139.752536},
    }, options)
    const promise = new Promise(resolve => {
      loader.load().then(google => {
        this.google = google
        this.map = new google.maps.Map(document.getElementById(options.id), options)
        this.isReady = true
        resolve()
      })
    })
    return promise
  }

  // マップタイプ変更
  setMapType(mapType) {
    this.map.setMapTypeId(mapType)    
  }

  // event
  // bounds_changed, center_changed, click, dblclick, drag, dragend, dragstart, heading_changed, idle, maptypeid_changed, mousemove, mouseout, mouseover, projection_changed, resize, rightclick, tilesloaded, tilt_changed, zoom_changed
  on(event, func) {
    if (event === 'longClick') {
      // long click
      let clickStart, clickEnd
      this.map.addListener('mousedown', () => {
        clickStart = new Date().getTime()
      })
      this.map.addListener('mouseup', e => {
        clickEnd = new Date().getTime()
        if (clickEnd - clickStart > 1000) {
          func(e)
        }
      })
    } else {
      this.map.addListener(event, func)
    }
  }

  // 取得
  getCenter() { 
    const center = this.map.getCenter()
    return {lat: center.lat(), lng: center.lng()}
  }
  getZoom() { return this.map.getZoom() }

  // 設定
  panTo(latlng) { this.map.setCenter(latlng) }
  setZoom(zoom) { this.map.setZoom(zoom) }

  // refresh
  refresh() {
    this.google.maps.event.trigger(this.map, 'resize')
  }

}
