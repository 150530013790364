<template>
  <fragment>

    <v-main>
      <Maps />
      <MapUIs />
    </v-main>

    <v-footer
      fixed
      padless
      :class="{
        ui: true,
        'hide-bottom': ! showUI,
        'full': ! (tabId === 'tab0' || isFixingGap)
      }"
      :style="{bottom: adsenseHeight + 'px'}"
    >
      <v-tabs-items
        v-model="tabId"
        touchless
      >
        <v-tab-item value="tab0"><Tab0 /></v-tab-item>
        <v-tab-item value="tab1"><Tab1 /></v-tab-item>
        <v-tab-item value="tab2"><Tab2 /></v-tab-item>
        <v-tab-item value="tab3"><Tab3 /></v-tab-item>
      </v-tabs-items>

      <v-tabs
        v-model="tabId"
        icons-and-text
        grow
        height="46"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="(tabItem, index) in tabItems"
          :key="index"
          :href="'#tab' + index"
          :disabled="isFixingGap"
        >
          {{ tabItem.title[lang] }}
          <v-icon>{{ tabItem.icon }}</v-icon>
        </v-tab>
      </v-tabs>
    </v-footer>

    <DialogFavorite />

  </fragment>
</template>

<script>
  import Maps from './Maps.vue'
  import MapUIs from './MapUIs.vue'
  import Tab0 from './Tab0.vue'
  import Tab1 from './Tab1.vue'
  import Tab2 from './Tab2.vue'
  import Tab3 from './Tab3.vue'
  import DialogFavorite from './DialogFavorite.vue'

  export default {
    data() {
      return {
        tabItems: [{
          id: 'tab0',
          title: {ja: '地図', en: 'map'},
          icon: 'mdi-map-outline',
        }, {
          id: 'tab1',
          title: {ja: '保存済み', en: 'saved'},
          icon: 'mdi-label-multiple-outline',
        }, {
          id: 'tab2',
          title: {ja: '投稿', en: 'post'},
          icon: 'mdi-comment-text-outline',
        }, {
          id: 'tab3',
          title: {ja: '設定', en: 'menu'},
          icon: 'mdi-dots-vertical',
        }],
      }
    },
    computed: {},
    async mounted() {
      const res = await cfs.getDoc('tests', 'test')
      console.log(res)
      // パラメータあり
      if (this.urlParams.lat && this.urlParams.lng) {
        this.currentCenter = {lat: this.urlParams.lat, lng: this.urlParams.lng}
        this.currentZoom = this.urlParams.z || 15
        this.age = _.toNumber(this.urlParams.age) || 76
      }
    },
    watch: {},
    methods: {},
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
    components: { Maps, MapUIs, Tab0, Tab1, Tab2, Tab3, DialogFavorite },
  }
</script>

<style lang="scss" scoped>
  .v-footer {
    display: block;
    overflow: auto;
    bottom: 100px; // JS
    &.full {
      top: 0
    }
  }
  .v-window-item {
    padding-bottom: 50px;
  }
  .v-tabs {
    position: absolute;
    bottom: 0;
    .v-tab {
      font-size: .8em;
    }
    .v-icon {
      margin-bottom: 0 !important;
    }
  }
</style>
