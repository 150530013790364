<template>
  <div id="map2"></div>
</template>

<script>
  import MapGoogle from '../commons/modules/mapGoogle'

  export default {
    data() {
      return {
        mapId: 'map2',
        map: new MapGoogle(),
        clickTimeout: null,
      }
    },
    computed: {
      isPrimary() { return this.primaryMap === this.mapId }
    },
    async mounted() {
      await this.map.draw({id: this.mapId, disableDefaultUI: true})
      this.addEvent()
    },
    watch: {
      // 地図移動
      currentCenter(obj) {
        if (! this.isPrimary && this.map.isReady && ! this.isFixingGap) {
          // 地図移動
          const fixedPosition = {lat: obj.lat - this.fixedGap.lat, lng: obj.lng - this.fixedGap.lng }
          this.map.panTo(fixedPosition)
        } else if (! this.isPrimary && this.isFixingGap) {
          // ずれ修正値更新
          this.fixedGap = {lat: obj.lat - this.map.getCenter().lat, lng: obj.lng - this.map.getCenter().lng}
        }
      },
      // ズーム変更
      currentZoom(num) {
        if (! this.isPrimary && this.map.isReady) {
          this.map.setZoom(num)
        }
      },
      // マップタイプ
      style() {
        this.map.setMapType(this.style)
      },
    },
    methods: {
      addEvent() {
        this.map.on('drag', () => {
          this.primaryMap = this.mapId
          this.currentCenter = this.map.getCenter()
        })
        this.map.on('zoom_changed', () => {
          this.primaryMap = this.mapId
          this.currentCenter = this.map.getCenter()
          this.currentZoom = _.round(this.map.getZoom())
        })
        this.map.on('dragend', () => {
          this.primaryMap = null
        })
        this.map.on('click', () => {
          this.showUI = ! this.showUI
        })
      }
    },
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  }
</script>

<style>
</style>

<style lang="scss" scoped>
  #map2 {
    height: 100%;
    width: 100%;
  }
</style>
