<template>
  <v-dialog
    v-model="toolbarDialog"
    fullscreen
    persistent
    no-click-animation
    :retain-focus="false"
  >
    <v-card>
      <!-- 履歴 -->
      <v-card-subtitle v-if="! places.length && histories.length">{{ $t('history') }}</v-card-subtitle>
      <v-list v-if="! places.length">
        <template v-for="place in histories">
          <v-list-item
            :key="place.placeId"
            @click="selectPlace(place)"
          >
            <v-list-item-icon><v-icon>mdi-clock-outline</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ place.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ replaceAddress(place.formattedAddress) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            :key="'divider' + place.placeId"
            inset
          ></v-divider>
        </template>
        <v-list-item>
          <v-spacer></v-spacer>
          <v-list-item-action>
            <v-btn
              text
              color="primary"
              @click="clearHistories"
            >{{ $t('clearHistory')}}</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <!-- 結果 -->
      <v-list v-if="places.length">
        <template v-for="place in places">
          <v-list-item
            :key="place.placeId"
            @click="selectPlace(place)"
          >
            <v-list-item-avatar><v-icon>mdi-map-marker-outline</v-icon></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ place.primaryText }}</v-list-item-title>
              <v-list-item-subtitle>{{ replaceAddress(place.secondaryText) }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action><v-icon>mdi-arrow-top-left</v-icon></v-list-item-action>
          </v-list-item>
          <v-divider
            :key="'divider' + place.placeId"
            inset
          ></v-divider>
        </template>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import places from '../commons/modules/places'

  export default {
    data() {
      return {
        places: []
      }
    },
    computed: {},
    async mounted() {},
    watch: {
      async query() {
        if (this.query) {
          const res = await places.getAutocomplete(this.query)
          this.places = res.length ? res : []
          console.log(res)
        } else {
          this.places = []
        }
      }
    },
    methods: {
      // 選択
      async selectPlace(place) {
        const res = await places.getPlace(place)
        console.log(res)
        if (_.has(res, 'latlng')) {
          this.panTo(res)
          this.addHistory(res)
          this.keyword = res.name
          this.query = ''
        }
      },
      // 地図移動
      panTo(place) {
        this.currentCenter = {lat: place.latlng[0], lng: place.latlng[1]}
        this.toolbarDialog = false
      },
      // 履歴保存
      addHistory(newPlace) {
        const histories = _.filter(this.histories, place => { return place.placeId !== newPlace.placeId })
        histories.unshift(newPlace)
        this.histories = _.take(histories, 10)
        console.log(this.histories)
      },
      // 履歴削除
      clearHistories() {
        this.histories = []
      },
      // アドレス調整
      replaceAddress(address) {
        return address ? address.replace('日本、', '').replace(' 日本', '') : ''
      }
    },
    i18n: {
      messages: {
        ja: {
          history: '履歴',
          clearHistory: '全ての履歴を削除する',
          trend: '検索トレンド',
        },
        en: {
          history: 'History',
          clearHistory: 'Clear all histories',
          trend: 'Trend'
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    padding-top: 90px !important;
  }
  .v-card__subtitle {
    padding-bottom: 0 !important;
  }
</style>
