<template>
  <fragment>
    <v-card
      v-for="(favorite, index) in favorites"
      :key="index"
    >
      <v-img
        :src="favorite.dataURL"
        max-width="100px"
      ></v-img>
      <v-card-title>{{ favorite.name }}</v-card-title>
      <v-card-text>{{ favorite.address }}</v-card-text>
      <v-card-text>{{ favorite.desc }}</v-card-text>
    </v-card>
    </fragment>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {},
    watch: {},
    methods: {},
    i18n: {
      messages: {
        ja: {},
        en: {}
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    margin: 16px;
  }
</style>
